import React from 'react'
import PropTypes from 'prop-types'
//css
import '../assets/css/login.css';
//components
import Modal from '../components/modal';
import Button from '../components/button';
//plugin
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Redirect } from 'react-router-dom';
//material
import CircularProgress from '@material-ui/core/CircularProgress';
import green from '@material-ui/core/colors/green';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
//geetest
import Geetest from 'react-geetest';
import GoogleLogin from 'react-google-login';
//config
import config from '../config';
import qs from 'qs';
//mobx
import { observer } from 'mobx-react';

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  wrapper: {
    margin: theme.spacing.unit,
    position: 'relative',
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
});

@observer
class Login extends React.Component {
  constructor(props) {
    super(props);
    this.login = this.login.bind(this);
    this.getEmail = this.getEmail.bind(this);
    this.responseGoogle = this.responseGoogle.bind(this);
    this.state = {
      geetest: false,
      gt: '',
      challenge: '',
      geetest_challenge: '',
      geetest_validate: '',
      geetest_seccode: '',
      prompt: '',
      loginPress: false
    }
  }
  componentDidMount() {
    this.geetestRegister();
    document.addEventListener('keydown', this.loginKeyUp);
    window.onmessage = function (e) {
      if (e.data && e.data.code && e.data.state) {
        window.location.href = window.location.origin + window.location.pathname + "?code=" + e.data.code + "&state=" + e.data.state;
      }
    }
    let self = this
    if (window.QC) {
      window.QC.Login({
        btnId: "qqLogin",	//插入按钮的节点id
      }, (e) => {
        window.QC.Login.getMe(function (openId, accessToken) {
          let params = {
            openid: openId
          }
          console.log("当前登录用户的openId为：" + openId);
          // if (!arr) {
          window.openId = openId;
          axios.defaults.withCredentials = true;
          axios.post(`//login.cocorobo.cn/api/user`, qs.stringify(params)).then(response => {
            if (response.data.active) {
              self.props.userStore.username = response.data.username;
              self.props.userStore.alias = response.data.alias;
              self.props.userStore.login = true;
              self.props.userStore.isLoading = false;
              self.postMessageToParent('loginVerify', 'logged', response.data);
              self.setState({
                loginPress: false
              })
            } else {

            }
          }).catch(err => {
            if (err && err.response && err.response.status === 401) {
              self.props.userStore.directToRegister = true;
            }
          })
          // }
        })
      });
    }

    // 微信登录
    if (this.getQueryString('code') && this.getQueryString('state')) {
      // oiztX1dwR-W2mBJ5HcvaSEB8yKGY
      axios.defaults.withCredentials = false;
      axios.get(`//weixin.cocorobo.cn/login.php?code=${this.getQueryString('code')}`).then(res => {
        if (res.data && res.data.openid) {
          let params = {
            openid: res.data.openid
          }
          window.openId = res.data.openid;
          axios.defaults.withCredentials = true;
          axios.post(`//login.cocorobo.cn/api/user`, qs.stringify(params)).then(response => {
            if (response.data.active) {
              self.props.userStore.username = response.data.username;
              self.props.userStore.alias = response.data.alias;
              self.props.userStore.login = true;
              self.props.userStore.isLoading = false;
              self.postMessageToParent('loginVerify', 'logged', response.data);
              self.setState({
                loginPress: false
              })
            }
          }).catch(err => {
            console.log(err.response, self.props)
            if (err && err.response && err.response.status === 401) {
              self.props.userStore.directToRegister = true;
              // self.props.history.push("/signup");
            }
          })
        }
      })
    }
  }

  // url 取值
  getQueryString = (name) => {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    var r = window.location.search.substr(1).match(reg);
    if (r != null) return unescape(r[2]); return null;
  }
  componentWillUnmount() {
    document.removeEventListener("keydown", this.loginKeyUp)
    this.props.userStore.directToRegister = false;
  }
  postMessageToParent(id, status, data) {
    //TODO: replace origin
    let result = {
      id,
      status,
      data
    }
    window.parent.postMessage(result, '*')
  }
  async login() {
    this.setState({
      prompt: '',
      loginPress: true,
    })
    this.props.userStore.isLoading = true;
    let self = this;
    axios.defaults.withCredentials = true;
    axios.post(`//login.cocorobo.cn/api/user`, {
      loginUsername: document.getElementById('Login__input--username').value.toLowerCase(),
      loginPassword: document.getElementById('Login__input--pwd').value,
      geetest_challenge: this.state.geetest_challenge,
      geetest_validate: this.state.geetest_validate,
      geetest_seccode: this.state.geetest_seccode
    })
      .then(function (response) {
        if (response.data == 'logged') {
          //get profile
          axios.defaults.withCredentials = true;
          axios.get(`//login.cocorobo.cn/api/profile`)
            .then(function (response) {
              self.props.userStore.username = response.data.username;
              self.props.userStore.alias = response.data.alias;
              self.props.userStore.login = true;
              self.props.userStore.isLoading = false;
              self.postMessageToParent('loginVerify', 'logged', response.data);
              self.setState({
                loginPress: false
              })
            })
            .catch(function (error) {
              console.log(error);
            })
        } else {
          console.log(response.data);
        }
      })
      .catch((error) => (
        error.response.status == 401
          ? error.response.data == 'reCaptcha verify fails.'
            ? self.setState({ geetest: true, prompt: '请点击验证', loginPress: false })
            : (self.setState({ prompt: '无效的用户名或密码', loginPress: false }), self.props.userStore.isLoading = false)
          : null
      )
      );
  }
  getEmail() {
    document.getElementById('Login__resetEmail').value = document.getElementById('Login__input--username').value;
  }
  resetSubmit() {
    let self = this;
    axios.defaults.withCredentials = true;
    axios.post(`${config.server}/reset`, {
      resetEmail: document.getElementById('Login__resetEmail').value,
    })
      .then(function (response) {
        if (response.data == 'SENT') {
          window.alert('邮件已发出');
          document.querySelectorAll('.Login__resetEmail--prompt')[0].innerHTML = '';
        } else {
          document.querySelectorAll('.Login__resetEmail--prompt')[0].innerHTML = '无效的邮箱';
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  responseGoogle(response) {
    let self = this;
    axios.defaults.withCredentials = true;
    axios.post(`${config.server}/googleSignIn`, {
      googleTokenId: response.tokenId
    })
      .then(function (res) {
        if (res.data.status == 'UNREGISTERED') {
          self.props.userStore.username = res.data.username;
          self.props.userStore.googleId = res.data.googleId;
          self.props.userStore.directToRegister = true;
        } else if (res.data == 'logged') {
          //get profile
          axios.get(`//login.cocorobo.cn/api/profile`)
            .then(function (response) {
              self.props.userStore.username = response.data.username;
              self.props.userStore.alias = response.data.alias;
              self.props.userStore.login = true;
              self.props.userStore.isLoading = false;
              self.postMessageToParent('loginVerify', 'logged', response.data);
            })
            .catch(function (error) {
              console.log(error);
            })
        }
      })
      .catch(function (err) {
        console.log(err);
      })
  }
  geetestRegister() {
    let self = this;
    axios.get(`${config.server}/geetest/pc-geetest/register`)
      .then((res) => {
        self.setState({
          challenge: res.data.challenge,
          gt: res.data.gt
        })
      })
      .catch((err) => {
        console.log(err);
      })
  }
  geetestValidate(isSuccess) {
    this.setState({
      geetest_challenge: isSuccess.geetest_challenge,
      geetest_validate: isSuccess.geetest_validate,
      geetest_seccode: isSuccess.geetest_seccode
    })
  }
  loginKeyUp = (e) => {
    if (e.keyCode === 13) {
      this.login()
    }
  }

  weixinLogin = () => {
    window.open("//cocorobo.cn/get-weixin-code.html?appid=wxe9d7fff3c659445f&redirect_uri=" + window.encodeURI("//cocorobo.cn/weixin.html") + "&response_type=code&scope=snsapi_login&state=" + Math.floor(Math.random() * 1000000), "微信登录", 'height=525,width=585, toolbar=no, menubar=no, scrollbars=no, status=no, location=yes, resizable=yes')
    // window.location.href = "//cocorobo.cn/get-weixin-code.html?appid=wxe9d7fff3c659445f&redirect_uri=" + window.encodeURI(window.location.href) + "&response_type=code&scope=snsapi_login&state=" + Math.floor(Math.random() * 1000000);
  }
  
  jumpQQ = () => {
    console.log('jumpQQ')
    window.open('https://graph.qq.com/oauth2.0/authorize?client_id=101882546&response_type=token&scope=all&redirect_uri=https%3A%2F%2Fcocorobo.cn%2Flogin', 'oauth2Login_10273', 'height=525,width=585, toolbar=no, menubar=no, scrollbars=no, status=no, location=yes, resizable=yes');
  }
  render() {
    if (this.props.userStore.login) {
      return <Redirect to='/success' />;
    }
    if (this.props.userStore.directToRegister) {
      return <Redirect to='/signup' />
    }
    const { loading, success } = this.state;
    const { classes } = this.props;
    const buttonClassname = classNames({
      [classes.buttonSuccess]: success,
    });
    return (
      <div>
        <div className='Login' id='Login'>
          <div className='Login__account'>
            <span className='Login__title'>电子邮件</span>
            <input type='text' className='Login__input' id='Login__input--username' />
            <p className='Login__prompt'>{this.state.prompt}</p>
          </div>
          <div className='Login__pwd'>
            <span className='Login__title'>密码</span>
            <input type='password' className='Login__input' id='Login__input--pwd' />
          </div>
          <div className="Login__register">
            <Link to='/signup'>注册</Link>
          </div>
          <div className='Login__button--wrapper'>
            {this.state.loginPress
              ? <React.Fragment>
                <button className='Login__button--press' onClick={this.login}>登录</button>
                <CircularProgress size={24} className={classes.buttonProgress} />
              </React.Fragment>
              : <button className='Login__button' onClick={this.login}>登录</button>
            }
          </div>
          <a uk-toggle='target:#Login__modal--forget' className='Login__forget' onClick={this.getEmail}>忘记密码？</a>

          <div className="Login__googleSignIn" id='googleButton'>
            <span>第三方登录</span>
            <div style={{ display: 'inline-block' }}>
              <span onClick={this.weixinLogin} style={{ marginRight: 10 }}>
                <img src={require('../assets/img/weixin.svg')} />
                微信
              </span>
              <span id="qqLogin" className="qqLogin" style={{ display:"none", height: 21 }}>
              </span>
              <span onClick={this.jumpQQ}>
                <img src={require('../assets/img/qq.svg')} />
                QQ
              </span>
            </div>
          </div>
        </div>
        <Modal id='Login__modal--forget'>
          <h3>重置您的密码</h3>
          <p>我们将向下列邮箱发送一封重置密码的邮件</p>
          <input className='Login__resetEmail' id='Login__resetEmail' />
          <span className='Login__resetEmail--prompt'></span>
          <Button name='确认' fn={this.resetSubmit} />
        </Modal>
      </div>
    )
  }
}

export default withStyles(styles)(Login);